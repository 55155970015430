import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UrlBuilder } from '../utils/url-builder';
import { ActivityCategory } from '../store/activity-categories.state';

enum ApiEndpoint {
  ActivityCategories = '/activity_categories',
}

@Injectable({ providedIn: 'root' })
export class ActivityProvider {
  constructor(private http: HttpClient) {}

  getActivityCategories(country: string): Observable<Array<ActivityCategory>> {
    const url = new UrlBuilder(ApiEndpoint.ActivityCategories).setQueryParams({ offset: 0, limit: 9999, country }).getUrl();

    return this.http
      .get<{ activityCategories: Array<ActivityCategory> }>(environment.apiBaseUrl + url)
      .pipe(map(response => response.activityCategories));
  }

  getActivityCategoryIconSvg(iconPath: string, country: string): Observable<string> {
    const url = new UrlBuilder(iconPath).setQueryParams({ country }).getUrl();

    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(blob => {
        const urlCreator = window.URL;
        return urlCreator.createObjectURL(blob);
      }),
    );
  }
}
