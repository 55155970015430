import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { STORAGE_KEY_ACTIVITY_CATEGORIES } from './constants';
import { ActivityProvider } from '../providers/activity.provider';
import { UserLocationState } from './user-location.state';
import { Translatable } from '../models/translation.model';

export enum ActivityCategoryType {
  GROW,
  PLAY,
  READ,
}

export interface ActivityCategory {
  code: ActivityCategoryType;
  createdAt: Date;
  full_count: string;
  iconUrl: string;
  id: number;
  name: Translatable;
  svgIconUrl: string;
  updatedAt: Date;
}

// This state is updated only on first startup
@Injectable({ providedIn: 'root' })
export class ActivityCategoriesState {
  constructor(
    private preferenceService: PreferencesService,
    private activityProvider: ActivityProvider,
    private userLocationState: UserLocationState,
  ) {}

  activityCategories$ = new BehaviorSubject<Array<ActivityCategory>>([]);
  private readonly STORAGE_KEY = STORAGE_KEY_ACTIVITY_CATEGORIES;

  async initialize(): Promise<void> {
    const storedActivityCategories = await this.get();

    if (storedActivityCategories) {
      this.activityCategories$.next(storedActivityCategories);
    } else {
      const country = this.userLocationState.userLocation$.value.countryCode;
      const serverActivityCategories = await firstValueFrom(this.activityProvider.getActivityCategories(country));

      await this.set(serverActivityCategories);
    }
  }

  async set(settings: Array<ActivityCategory>): Promise<void> {
    this.activityCategories$.next(settings);
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(settings));
  }

  private async get(): Promise<Array<ActivityCategory> | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }
}
